// APHIDS Specific Styles
.govuk-table__cell {
  padding: 5px;
}

.mb-0 {
  margin-bottom: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.govuk-hint {
  color: #707070
}

.header-menu li {
  list-style-type: none;
  font-size: 16px;
}

.button-link {
  color: rgb(0, 94, 165);
  border: none;
  background-color: transparent;

  font-family: "Inter UI", sans-serif;
  font-size: inherit;
  padding: 0;

  &:hover {
    color: rgb(43, 140, 196);
    cursor: pointer;
  }
}

.govuk-phase-banner {
  padding: 10px 30px;
  border: 0;
  background-color: #f5f5f5;
}

.govuk-tag {
  padding: 5px 8px 4px;
  line-height: 1;
}

.govuk-tabs {
  @include govuk-font(19);
  background-color: transparent;
}

.govuk-tabs__panel {
  background-color: #FFF;

  pre {
    margin: 0;
  }
}

@include govuk-media-query($from: tablet) {
  .app-navigation {
    font-size: 24px;
  }
}

.js-enabled {
  @include govuk-media-query($from: tablet) {
    .govuk-tabs__list-item {
      background-color: #d4d4d4;
    }
    .govuk-tabs__list-item--selected {
      background-color: #FFF;
    }
  }
}

.app-pane__body {
  @include govuk-media-query($from: tablet) {
    > * {
      overflow-x: auto;
    }
  }
}

.ho-body {
  font-size: 19px;
}

.header-title {
  text-align: center;
  padding: 0 20px;
}

.header-name {
  font-size: 24px;
}

%govuk-label--xs {
  @include govuk-font($size: 16);
}

.pass-holder-main-heading {
  float: left;
}

.pass-holder-main-heading-container {
  float: left;
}

.with-sub-heading {
  margin-bottom: 10px;
}

.without-sub-heading {
  margin-bottom: 30px;
}

.govuk-grid-column-one-quarter {
  .govuk-input {
    height: 30px;
    font-size: 16px;
  }

  .govuk-label {
    @extend %govuk-label--xs;
  }

  .govuk-radios__item, .govuk-checkboxes__item {
    min-height: 30px;
  }

  .govuk-radios__input, .govuk-checkboxes__input {
    width: 34px;
    height: 34px;
  }

  .govuk-radios__label, .govuk-checkboxes__label {
    padding: 5px 5px 5px;
  }

  .govuk-radios__label::before, .govuk-checkboxes__label::before {
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
  }

  .govuk-checkboxes__label::after {
    top: 8px;
    left: 6px;
    width: 18px;
    height: 11px;
  }

  .govuk-radios__label::after {
    top: 7px;
    left: 7px;
    width: 0;
    height: 0;
    border: 8px solid currentColor;
  }

  .text-filter-container ul {
    font-size: 16px;
    list-style: none;
    padding: 0;
  }
}
