@import url('https://rsms.me/inter/inter-ui.css');

// global styles for <a> and <p> tags
$govuk-global-styles: true;
$govuk-font-family: 'Inter UI', sans-serif;

$govuk-compatibility-govuktemplate: true; // needed for compatibility with HO Design System

// Import GOV.UK Frontend
@import "node_modules/govuk-frontend/govuk/all";

// Overrides
a:link {
  display: inline-block;
  min-height: 24px;
  min-width: 24px;
}

// Colour pallette
$purple: #8F23B3;
$base-grey-light: #F5F5F5;
$base-grey-medium: #F1F1F1;
$border-grey: darken( $base-grey-medium, 15% );
$dark-grey: #3B3B3B;
$app-light-grey: #f8f8f8;
$app-code-color: #dd1144;

.ho-body {
  background-color: $base-grey-light;
  font-size: 18px;
  font-family: $govuk-font-family;
}

footer {
  box-shadow: 0 50vh 0 50vh #FFF;
}

caption {font-family: 'Inter UI', sans-serif;}
$border-radius: 4px;
$box-shadow: 0 2px 10px rgba(0,0,0, 0.5);

// Import styles
@import "system/top-nav";
@import "system/mobile-nav";
@import "system/panes";
@import "system/snippets";
@import "system/content";
@import "system/system-header";
@import "system/no-panel";
@import "system/subnav";
@import "system/tabs";
@import "system/system-footer";
@import "system/tables";
@import "system/highlight";

// Styles
@import "styles";

// GOV.UK Patterns
@import "patterns/check-your-answers";
@import "patterns/step-by-step-navigation";
@import "patterns/task-list";
@import "patterns/related-items";
@import "components/cookie-banner";
@import "patterns/old-classes";

// Home Office Components
@import "components/header";
@import "components/footer";
@import "components/checkboxes";
@import "components/alerts";
@import "components/radios";
@import "components/buttons";
@import "components/cards";
@import "components/labels";
@import "components/highlights";
@import "components/table-multiselect";
@import "components/confirm-pages";
@import "components/side-menu";
@import "components/navigation";
@import "components/pagination";
@import "components/timeline";
@import "components/status";

// Home Office Patterns
@import "patterns/case";
@import "patterns/flashcard";
@import "patterns/modal";
@import "patterns/help";
@import "patterns/confirmation-loop";

// Home Office overrides

.govuk-heading-m, h2.govuk-visually-hidden {
  font-size: 24px!important;
  font-size: 1.5rem!important;
  line-height: 1.3!important;
}

// Support nested GDS accordians
.govuk-accordion__section--expanded .govuk-accordion__section-content {
  .govuk-accordion__section-content {
    display:none;
  }
  .govuk-accordion__section--expanded {
    .govuk-accordion__section-content {
      display:block;
    }
  }
}

.js-enabled {
  .accordion__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
  }

  .accordion__icon:after,
  .accordion__icon:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 25%;
    margin: auto;
    border: 2px solid transparent;
    background-color: govuk-colour("black");
  }

  .accordion__icon:before {
    width: 100%;
  }

  .accordion__icon:after {
    height: 100%;
  }

  .govuk-accordion__section--expanded > .govuk-accordion__section-header .accordion__icon:after {
    content: " ";
    display: none;
  }

  .govuk-accordion__section-content .govuk-accordion__section-button {
    font-size: 19px !important;
    font-weight: normal !important;
  }
}

// Helpers

.govuk-main-wrapper {
  padding-top: 0;
}

.mt-15 {
  margin-top: 15px;
}
.mb-30 {
  margin-bottom: 30px;
}

.js-hidden {
  display: none;
}

.example h2 {
  margin-bottom: 1.8em;
}

// Icon spacer
.side-space {
	margin-right: .5em;
}

.icon-medium {
  font-size: 24px;
  &:before {
    vertical-align: bottom;
    padding-bottom: 1em;
  }
}

.icon-small {
  font-size: 18px;
  &:before {
    vertical-align: middle;
    padding-bottom: 1em;
  }
}

.block-space {
	margin: 15px 0;
}

@media screen and (max-width: 1020px ) {
  .content-width {
    margin: 0!important;
  }
}

@media screen and (max-width: 640px ) {
  .full-width {
    font-size: 16px;
  }
}

.govuk-scroll-disabled {
  overflow: hidden;
}